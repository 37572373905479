import Highcharts, { Annotation } from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {
    AuthenticatedUser,
    RecordingAnnotation,
    SignalEntry,
    SignalList,
    SignalListEntry,
    SignalMetadata,
} from '../../../../../api/CloudApi/types'
import { ANALYTICS_CHART_TYPE_KEY, AnalyticsPanelType, GraphSettings, PanelKey, SharedExtremes } from '../../Types'
import { ZonedDateTime } from '@js-joda/core'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../../../utils/toast'
import { Spinner } from 'react-bootstrap'
import LoadingContainer from '../../../../../components/LoadingContainer'

interface TimeSeriesChartProps {
    currentUser: AuthenticatedUser | undefined
    maxHeight: number
    panelKey: PanelKey
    plottableSignalData: SignalList | undefined
    availableAnnotations: Array<RecordingAnnotation> | undefined
    selectedSignals: Array<SignalEntry>
    hiddenSignals: Array<SignalEntry>
    graphSettings: GraphSettings
    selectedAnnotation: RecordingAnnotation | undefined
    isAddAnnotationActive: boolean
    sharedExtremes: SharedExtremes
    setSharedExtrems: (newExtremes: SharedExtremes) => void
    setSelectedAnnotation: (annotation: RecordingAnnotation | undefined) => void
    setShowAddAnnotationModal: (show: boolean) => void
    setLastClickedAnnotationTimestamp: (timestamp: number) => void
    setLastClickedAnnotationTimestampEnd: (timestamp: number) => void
}

enum highchartsConstructorType {
    DEFAULT_CHART = 'chart',
    STOCK_CHART = 'stockChart',
    MAP_CHART = 'mapChart',
    GANTT_CHART = 'ganttChart',
}

export default function TimeSeriesChart(props: TimeSeriesChartProps) {
    const chartRef = useRef<HighchartsReact.RefObject>(null)
    const [annotationsToShowInGraph, setAnnotationsToShowInGraph] = useState<Array<RecordingAnnotation>>(
        props.availableAnnotations ?? []
    )

    useEffect(() => {
        if (props.availableAnnotations) {
            if (props.graphSettings.showOnlyMyAnnotations) {
                setAnnotationsToShowInGraph(
                    props.availableAnnotations.filter((it) => {
                        console.log(`createdBy.uid=${it.createdBy.uid} and currentUser.uid=${props.currentUser?.uid}`)
                        return it.createdBy.uid === props.currentUser?.uid
                    })
                )
            } else if (props.graphSettings.showAllAnnotations) {
                setAnnotationsToShowInGraph(props.availableAnnotations)
            } else {
                setAnnotationsToShowInGraph([])
            }
        }
    }, [props.graphSettings, props.availableAnnotations])

    function findClosestYValueFromXValue(timestamp_iso: string, signalDataList: SignalList | undefined): any {
        const timestamp = ZonedDateTime.parse(timestamp_iso).toInstant().toEpochMilli()
        signalDataList?.signals.filter(
            (it) => !props.hiddenSignals.find((signal) => `${signal.frameName}.${signal.name}` === it.name)
        )
        const closestTimestamp = (signalDataList?.signals ?? [])
            .filter((signalListEntry) => {
                // Remove all hidden signals
                return !props.hiddenSignals.some(
                    (hiddenSignal) => `${hiddenSignal.frameName}.${hiddenSignal.name}` === signalListEntry.name
                )
            })
            .flatMap((signalListEntry) => {
                // Flatten and return all x, y value pairs
                const closestDataPoint = signalListEntry.signals.flatMap((signalDataPoint) => {
                    return { x: signalDataPoint[0], y: signalDataPoint[1] }
                })
                return closestDataPoint
            })
            .find((dataPoint) => dataPoint.x > timestamp)
        return closestTimestamp?.y ?? 0
    }

    const storeExtremes = (e: Highcharts.AxisSetExtremesEventObject) => {
        switch (e.trigger as Highcharts.AxisExtremesTriggerValue) {
            case 'navigator':
            case 'pan':
            case 'scrollbar':
            case 'zoom':
            case 'rangeSelectorButton':
                props.setSharedExtrems({ xMax: Math.trunc(e.max), xMin: Math.trunc(e.min) })
                //syncExtremes(e)
                break

            case 'rangeSelectorInput':
            case 'traverseUpButton':
                // do nothing
                break
        }
    }

    const getSignalMetadata = (entry: SignalListEntry): SignalMetadata | undefined => {
        const metadata = props.plottableSignalData?.metadata?.filter((m) => m.name === entry.name)
        if (metadata && metadata.length > 0) {
            return metadata[0]
        }
        return undefined
    }

    const commentIconAsSvgString = (color: 'white' | 'black') => {
        return `<svg width="9" height="9" fill="${color}">
  <path d="M0.75 0.75H7.75C8.02614 0.75 8.25 0.973858 8.25 1.25V5.75C8.25 6.02614 8.02614 6.25 7.75 6.25H3L0.75 7.75V1.25C0.75 0.973858 0.973858 0.75 1.25 0.75Z" 
        fill="${color}"/>
</svg>
`
    }

    const constructSignalNameKey = (signalName: string, frameName: string, namespace: string) => {
        const name = `${namespace}-${frameName}-${signalName}`.toLowerCase()
        return name
    }

    const annotationBackgroundColor = (annotation: RecordingAnnotation, opacity?: '20%' | '80%') => {
        const opacityInHex = opacity === '20%' ? '33' : opacity === '80%' ? 'cc' : ''
        return annotation.id === props.selectedAnnotation?.id ? `#0b8551${opacityInHex}` : `#002649${opacityInHex}`
    }

    const highchartsOptions = (
        availableAnnotations: Array<RecordingAnnotation>,
        plottableSignalData: SignalList,
        hiddenSignals: SignalEntry[],
        graphSettings: GraphSettings,
        panelKey: PanelKey,
        isAddAnnotationActive: boolean,
        sharedExtremes: SharedExtremes,
        setShowAddAnnotationModal: (show: boolean) => void,
        setSelectedAnnotation: (selectedAnnotation: RecordingAnnotation | undefined) => void
    ) => {
        const plottableSignalDataWithoutHiddenSignals =
            plottableSignalData.signals.filter(
                (it) => !hiddenSignals.find((signal) => `${signal.frameName}.${signal.name}` === it.name)
            ) ?? []
        const series = plottableSignalDataWithoutHiddenSignals.map((entry, i) => {
            const metadata = getSignalMetadata(entry)
            const [frameName, signalName] = entry.name.split('.')
            const unit = metadata?.unit ? `(${metadata.unit})` : ''
            const color = Highcharts.getOptions().colors![i]
            const signalKey = constructSignalNameKey(signalName, frameName, metadata?.namespace ?? 'N/A')
            // signalColorsInChart.current.set(signalKey, color)
            const attributes = {
                dataGrouping: {
                    enabled: false,
                },
                boostThreshold: 1000,
                id: signalKey,
                name: `${entry.name} ${unit}`,
                data: entry.signals,
                selected: true,
                yAxis: i,
                color: color,
                tooltip: {
                    valueDecimals: 5,
                },
            }
            return attributes
        })

        const yAxis = plottableSignalDataWithoutHiddenSignals.map((entry, i) => {
            const a = {
                //showLastLabel: true,
                visible: graphSettings.showYAxis,
                zoomEnabled: true,
                crosshair: true,
                lineWidth: 1,
                opposite: i % 2 === 0,
                title: {
                    text: entry.name,
                    style: {
                        color: Highcharts.getOptions().colors![i],
                    },
                },
            }
            return a
        })

        // Get the maxDuration on the annotations and use this to get the correct Z index by reversing
        // high duration to low zIndex
        const maxDuration = Math.max(
            ...annotationsToShowInGraph
                .filter((b) => b.durationMs !== undefined && b.durationMs !== null)
                .map((band) => band.durationMs as number)
        )

        const options: any = {
            credits: {
                enabled: false,
            },
            boost: {
                debug: { showSkipSummary: true },
                //usePreAllocated: true,
                //useGPUTranslations: true,
            },
            chart: {
                // BEGIN Custom attributes
                chartType: AnalyticsPanelType.SIGNAL_TIME_SERIES,
                panelKey: panelKey.key,
                // END Custom attributes
                style: {
                    fontFamily: 'LexendDecaRegular',
                },
                backgroundColor: '#fff',
                zooming: {
                    type: 'x',
                    mouseWheel: false,
                },
                panning: true,
                panKey: 'shift',
                zoomType: 'x',
                height: '440',
                events: {
                    selection: function (event: any) {
                        if (isAddAnnotationActive) {
                            props.setLastClickedAnnotationTimestamp(Math.round(event.xAxis[0].min))
                            props.setLastClickedAnnotationTimestampEnd(Math.round(event.xAxis[0].max))
                            setShowAddAnnotationModal(true)
                            return false
                        }
                        return true
                    },

                    click: function (event: any) {
                        if (isAddAnnotationActive) {
                            props.setLastClickedAnnotationTimestamp(Math.round(event.xAxis[0].value))
                            props.setLastClickedAnnotationTimestampEnd(0)
                            setShowAddAnnotationModal(true)
                        }
                    },
                    afterUpdate: (it: Highcharts.Chart) => {
                        if (
                            chartRef.current?.chart !== undefined &&
                            props.availableAnnotations !== undefined &&
                            props.plottableSignalData !== undefined &&
                            props.sharedExtremes.xMin !== undefined &&
                            props.sharedExtremes.xMax !== undefined
                        ) {
                            // TODO this is the only place where we interace with the chart directly.
                            // Would be nice to be able to remove this one day and only rely on the React state changes...
                            chartRef.current.chart.xAxis[0].setExtremes(
                                props.sharedExtremes.xMin,
                                props.sharedExtremes.xMax,
                                true,
                                false,
                                { trigger: 'setExtremesIfPresentOnAfterUpdate' }
                            )
                        }
                    },
                },
            },

            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 400,
                            maxHeight: 200,
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal',
                            },
                        },
                    },
                ],
            },

            navigator: { enabled: true },

            rangeSelector: {
                buttons: [
                    {
                        type: 'second',
                        count: 5,
                        text: '5s',
                    },
                    {
                        type: 'second',
                        count: 10,
                        text: '10s',
                    },
                    {
                        type: 'second',
                        count: 30,
                        text: '30s',
                    },
                    {
                        type: 'minute',
                        count: 1,
                        text: '1min',
                    },
                    {
                        type: 'minute',
                        count: 5,
                        text: '5m',
                    },
                    {
                        type: 'all',
                        text: 'All',
                    },
                ],
                inputEnabled: false, // it supports only days
                selected: 5,
            },

            legend: {
                enabled: false,
            },

            series: series,

            plotOptions: {
                series: {
                    animation: false,
                },
                flags: {
                    accessibility: {
                        exposeAsGroupOnly: true,
                        description: 'Flagged events.',
                    },
                },
                line: {
                    marker: {
                        enabled: true,
                    },
                },
            },
            yAxis: yAxis,
            xAxis: {
                min: sharedExtremes.xMin,
                max: sharedExtremes.xMax,
                visible: graphSettings.showXAxis,
                type: 'datetime',
                crosshair: true,
                events: {
                    setExtremes: (event: Highcharts.AxisSetExtremesEventObject) => {
                        // Do nothing
                    },
                    afterSetExtremes: storeExtremes,
                },

                plotBands: annotationsToShowInGraph
                    .filter((it) => it.durationMs !== undefined && it.durationMs !== null)
                    .map((it, index) => {
                        const timestamp = ZonedDateTime.parse(it.timestamp).toInstant().toEpochMilli()
                        return {
                            id: `${it.title}-${it.created}`,
                            from: ZonedDateTime.parse(it.timestamp).toInstant().toEpochMilli(),
                            to: timestamp + it.durationMs!!,
                            zIndex: maxDuration - it.durationMs!!, // High duration, low Z
                            events: {
                                click: function (e: any) {
                                    setSelectedAnnotation(
                                        availableAnnotations.find((annotation) => annotation.timestamp === it.timestamp)
                                    )
                                },
                            },
                            color: annotationBackgroundColor(it, '20%'),
                            borderWidth: 1,
                            borderColor: annotationBackgroundColor(it, '80%'),
                            label: {
                                align: 'left',
                                useHTML: true,
                                formatter: function (): string {
                                    return `
                                <div class="lexend-regular text-dark px-2">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <p class="remotive-font-xs mb-0 me-2">${it.title}</p>
                                        <p class="mb-0 remotive-font-xs">${
                                            it.comments.length
                                        } <span>${commentIconAsSvgString('black')}</span></p>
                                    </div>
                                    
                                </div>`
                                },
                            },
                        }
                    }),

                plotLines: annotationsToShowInGraph
                    .filter((it) => it.durationMs === null)
                    .map((it) => {
                        return {
                            id: `${it.title}-${it.created}`,
                            value: ZonedDateTime.parse(it.timestamp).toInstant().toEpochMilli(),
                            width: 2,
                            zIndex: 1,
                            events: {
                                click: function (e: any) {
                                    setSelectedAnnotation(
                                        availableAnnotations.find((annotation) => annotation.timestamp === it.timestamp)
                                    )
                                },
                            },
                        }
                    }),
            },
            annotations: annotationsToShowInGraph
                .filter((it) => it.durationMs === null)
                .map((it) => {
                    return {
                        id: `${it.title}-${it.created}`,
                        events: {
                            click: function (e: any) {
                                setSelectedAnnotation(
                                    availableAnnotations.find((annotation) => annotation.timestamp === it.timestamp)
                                )
                            },
                        },
                        draggable: false,
                        labels: [
                            {
                                backgroundColor: annotationBackgroundColor(it, '80%'),
                                borderWidth: 0,
                                borderRadius: 10,
                                key: `${it.id}`,
                                useHTML: true,
                                formatter: function (): string {
                                    return `
                                <div class="lexend-regular text-light px-2">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <p class="remotive-font-xs mb-0 me-2">${it.title}</p>
                                        <p class="mb-0 remotive-font-xs">${
                                            it.comments.length
                                        } <span>${commentIconAsSvgString('white')}</span></p>
                                    </div>
                                    
                                </div>`
                                },
                                point: {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x: ZonedDateTime.parse(it.timestamp).toInstant().toEpochMilli(),
                                    y: findClosestYValueFromXValue(it.timestamp, plottableSignalData), // Set the label y-value to the closest y-value of timestamp
                                },
                            },
                        ],
                    }
                }),
        }

        return options
    }

    const higchartsGraphOrEmpty = () => {
        if (props.selectedSignals.length > 0 && props.selectedSignals.length === props.hiddenSignals.length) {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <p className="m-0 remotive-font-xs">All signals are hidden</p>
                </div>
            )
        }
        if (props.plottableSignalData === undefined || props.availableAnnotations === undefined) {
            return <></>
        }
        return (
            <HighchartsReact
                data-ANALYTICS_CHART_TYPE_KEY={AnalyticsPanelType.SIGNAL_TIME_SERIES}
                highcharts={Highcharts}
                options={highchartsOptions(
                    props.availableAnnotations,
                    props.plottableSignalData,
                    props.hiddenSignals,
                    props.graphSettings,
                    props.panelKey,
                    props.isAddAnnotationActive,
                    props.sharedExtremes,
                    props.setShowAddAnnotationModal,
                    props.setSelectedAnnotation
                )}
                constructorType={highchartsConstructorType.STOCK_CHART}
                className="chart"
                updateArgs={[true, true, false]} //redraw, oneToOne, Animation
                ref={chartRef}
            />
        )
    }

    console.log(`Rendering due to state change in ${props.panelKey.key}`)

    return (
        <div
            className={`${props.selectedAnnotation === undefined ? 'col-12' : 'col-6 col-md-8 col-xxl-9 pe-1'}`}
            style={{
                maxHeight: props.maxHeight,
            }}
            id={props.panelKey.key}
        >
            {higchartsGraphOrEmpty()}
        </div>
    )
}
