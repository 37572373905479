import { Accordion, Button, Card, Dropdown, Form, InputGroup, OverlayTrigger, Popover, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import {
    AuthenticatedUser,
    FeatureEnabledStateRequest,
    FeatureKey,
    FrameEntry,
    ProcessingError,
    Project,
    RecordingAnnotation,
    RecordingSession,
    UserBillableUnitInfo,
    VisualizeProcessingTracker,
    VisualizeProcessingTrackerStatus,
    VisualizeProcessingTrackerStep,
} from '../../../api/CloudApi/types'
import { useEffect, useRef, useState } from 'react'
import CloudApi from '../../../api/CloudApi'
import {
    AddIcon,
    CopyIcon,
    DropdownIcon,
    DropupIcon,
    JitterIcon,
    MapIcon,
    ShareIcon,
    SuccessIcon,
    WarningIcon,
} from '../../../assets/Icons'
import TimeDistributionContainer from './Panels/TimeDistributionContainer'
import TimeSeriesContainer from './Panels/TimeSeriesContainer'
import LoadingContainer from '../../../components/LoadingContainer'
import { hasPermission, Permission } from '../../../utils/permission'
import { Dashboard, MapPanel, PanelKey, SharedExtremes, TimeDistributionPanel, TimeSeriesPanel } from './Types'
import { convertToStorableDashboard, getDashboard, storeDashboard } from './storageUtils'
import MapContainer from './Panels/MapContainer'
import { useSearchParams } from 'react-router-dom'
import { formattedToastMessage } from '../../../utils/toast'
import { DASHBOARD_PARAM } from '../../../utils/queryParams'
import { TabEnum } from '..'
import SignalDataBars from './OverviewAccordion/SignalDataBar'
import {
    AnalyticsContext,
    createAnalyticsTrackingKey,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from '../../../utils/ProductAnalytics'
import { ComponentState } from '../../../types/ComponentState'
import ErrorContainer from '../../../components/ErrorContainer'
import { InfoRounded, WarningRounded } from '@mui/icons-material'
import OverviewAccordion from './OverviewAccordion'
import { annotationFeatureEnabled } from '../../../utils/featureToggle'

const PROCESSING_STATUS_POLL_INTERVAL = 4_000

const AnalyticsActions = {
    ADD_TIME_DISTRIBUTION_PANEL: createAnalyticsTrackingKey(AnalyticsContext.VISUALIZE_TAB, 'AddTimeDistributionPanel'),
    REMOVE_TIME_DISTRIBUTION_PANEL: createAnalyticsTrackingKey(
        AnalyticsContext.VISUALIZE_TAB,
        'RemoveTimeDistributionPanel'
    ),
    ADD_TIME_SERIES_PANEL: createAnalyticsTrackingKey(AnalyticsContext.VISUALIZE_TAB, 'AddTimeSeriesPanel'),
    REMOVE_TIME_SERIES_PANEL: createAnalyticsTrackingKey(AnalyticsContext.VISUALIZE_TAB, 'RemoveTimeSeriesPanel'),
    ADD_MAP_PANEL: createAnalyticsTrackingKey(AnalyticsContext.VISUALIZE_TAB, 'AddMapPanel'),
    REMOVE_MAP_PANEL: createAnalyticsTrackingKey(AnalyticsContext.VISUALIZE_TAB, 'RemoveMapPanel'),
    PREAPRE_FOR_ANALYTICS: createAnalyticsTrackingKey(AnalyticsContext.VISUALIZE_TAB, 'PrepareForAnalytics'),
    REQUEST_ACCESS: createAnalyticsTrackingKey(AnalyticsContext.VISUALIZE_TAB, 'RequestAccess'),
}

interface AnalyticsTabProps {
    currentUser: AuthenticatedUser | undefined
    currentRecording: RecordingSession | undefined
    currentProject: Project | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
}

export function AnalyticsTab(props: AnalyticsTabProps) {
    const [componentState, setComponentState] = useState(ComponentState.LOADING)

    const [availableFrameEntries, setAvailableFrameEntries] = useState<Array<FrameEntry>>()
    const [availableAnnotations, setAvailableAnnotations] = useState<Array<RecordingAnnotation> | undefined>(undefined)

    const [dashboard, setDashboard] = useState<Dashboard | undefined>(undefined)
    const [shareableDashboardLink, setShareableDashboardLink] = useState<string | undefined>(undefined)

    const [showDropdown, setShowDropdown] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const [latestProcessingTracker, setLatestProcessingTracker] = useState<VisualizeProcessingTracker | undefined>(
        undefined
    )

    const [sharedExtremes, setSharedExtremes] = useState<SharedExtremes>({
        xMin: undefined,
        xMax: undefined,
    })

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.currentUser,
        billableUnit: props.currentBillableUnit,
    } as ProductAnalyticsProps)

    useEffect(() => {
        const fetchData = async () => {
            if (
                props.currentProject !== undefined &&
                props.currentRecording !== undefined &&
                latestProcessingTracker?.status === VisualizeProcessingTrackerStatus.FINISHED
            ) {
                const frameEntries = await listFrameEntries(props.currentProject, props.currentRecording)
                convertAndSetFrameEntries(frameEntries.data)
                setComponentState(ComponentState.DONE)
            }
        }
        fetchData()
    }, [latestProcessingTracker])

    useEffect(() => {
        // Use this as "OnMounted"
        if (props.currentProject !== undefined && props.currentRecording?.sessionId !== undefined) {
            onMounted(props.currentProject, props.currentRecording)
        }
    }, [props.currentProject, props.currentRecording])

    useEffect(() => {
        if (props.currentRecording !== undefined && props.currentProject !== undefined) {
            fetchAndSetAvailableAnnotations(props.currentRecording, props.currentProject)
        }
    }, [props.currentRecording, props.currentProject])

    useEffect(() => {
        if (
            props.currentProject !== undefined &&
            props.currentRecording !== undefined &&
            componentState === ComponentState.CRUNCHING
        ) {
            const interval = setInterval(async () => {
                onPollInterval(props.currentProject!, props.currentRecording!)
            }, PROCESSING_STATUS_POLL_INTERVAL)
            return () => {
                console.log('Clear')
                clearInterval(interval)
            }
        }
    }, [componentState, props.currentProject, props.currentRecording])

    useEffect(() => {
        const queryParamDashboard = searchParams.get(DASHBOARD_PARAM)
        if (
            props.currentRecording !== undefined &&
            props.currentProject !== undefined &&
            availableFrameEntries &&
            availableFrameEntries.length > 0
        ) {
            if (queryParamDashboard) {
                try {
                    const dashboard: Dashboard = JSON.parse(queryParamDashboard)
                    for (const key of Object.keys(dashboard)) {
                        if (key === 'tsp' || key === 'tdp' || key === 'mp') {
                            throw Error('You tried to open a dashboard that is in an obsolete format.', {
                                cause: 'deprecated',
                            })
                        }
                    }
                    searchParams.delete(DASHBOARD_PARAM)
                    setSearchParams(searchParams)
                    setDashboard(dashboard)
                } catch (error: any) {
                    console.error(error)
                    searchParams.delete(DASHBOARD_PARAM)
                    setSearchParams(searchParams)
                    toast.error(
                        formattedToastMessage(
                            `Dashboard error`,
                            error.cause === 'deprecated'
                                ? 'You tried to open a dashboard that is in an obsolete format.'
                                : `There was an issue when opening the shared dashboard.`
                        ),
                        { autoClose: 20_000 }
                    )
                }
            } else {
                const dashboard = getDashboard(props.currentRecording, props.currentProject, availableFrameEntries)
                if (dashboard !== undefined) {
                    setDashboard(dashboard)
                }
            }
        }
    }, [availableFrameEntries, props.currentRecording, props.currentProject])

    useEffect(() => {
        if (dashboard !== undefined && props.currentRecording !== undefined && props.currentProject !== undefined) {
            storeDashboard(props.currentRecording, props.currentProject, dashboard)
            setShareableDashboardLink(
                `${window.location.origin}${window.location.pathname}?tab=${
                    TabEnum.VISUALIZE
                }&${DASHBOARD_PARAM}=${urlEncodedDashboard(dashboard)}`
            )
        }
    }, [dashboard])

    const onMounted = async (project: Project, recordingSession: RecordingSession) => {
        try {
            const frameEntriesResponse = await listFrameEntries(project, recordingSession)
            if (frameEntriesResponse.status === 200) {
                convertAndSetFrameEntries(frameEntriesResponse.data)
                setComponentState(ComponentState.DONE)
            } else if (frameEntriesResponse.status === 403) {
                setComponentState(ComponentState.NOT_ENABLED)
            } else if (frameEntriesResponse.status === 404) {
                const visualizeProcessingTrackerResponse = await getVisualizeProcessingTracker(
                    project,
                    recordingSession
                )
                if (visualizeProcessingTrackerResponse.status === 200) {
                    onPollInterval(project, recordingSession)
                } else {
                    setComponentState(ComponentState.PROCESSING_REQUIRED)
                }
            } else {
                setComponentState(ComponentState.ERROR)
            }
        } catch (e: any) {
            console.error(e)
            setComponentState(ComponentState.ERROR)
        }
    }

    const safeFetchAndSetAvailableAnnotations = async () => {
        if (props.currentRecording !== undefined && props.currentProject !== undefined) {
            const annotations = await fetchAndSetAvailableAnnotations(props.currentRecording, props.currentProject)
            return annotations
        } else {
            toast.error(
                formattedToastMessage(
                    'Error',
                    'Could not refresh the annotation due to a parameter error. Please refresh the page and try again.'
                )
            )
        }

        return []
    }

    const fetchAndSetAvailableAnnotations = async (recordingSession: RecordingSession, currentProject: Project) => {
        const annotations = await fetchAvailableAnnotations(recordingSession, currentProject)
        setAvailableAnnotations(annotations)
        return annotations
    }

    const fetchAvailableAnnotations = async (recordingSession: RecordingSession, currentProject: Project) => {
        if (annotationFeatureEnabled(currentProject)) {
            const response = await CloudApi.listRecordingAnnotations(currentProject, recordingSession)
            return response.data
        } else return []
    }

    const convertAndSetFrameEntries = (frameEntries: Array<FrameEntry> | undefined) => {
        setAvailableFrameEntries(
            (frameEntries ?? []).map((entry) => {
                return {
                    ...entry,
                    signals: entry.signals.map((it) => {
                        return { ...it, frameName: entry.name }
                    }),
                }
            })
        )
    }

    const onPollInterval = async (project: Project, recordingSession: RecordingSession) => {
        try {
            const res = await CloudApi.getVisualizeProcessingTracker(project.uid, recordingSession.sessionId, {
                validateStatus: (code) => true,
            })
            if (res.status === 200) {
                setComponentState(ComponentState.CRUNCHING)
                setLatestProcessingTracker(res.data)
            } else if (res.status === 404) {
                if (componentState !== ComponentState.QUEUED) {
                    setComponentState(ComponentState.PROCESSING_REQUIRED)
                }
            } else if (res.status === 403) {
                setComponentState(ComponentState.NOT_ENABLED)
            } else {
                setComponentState(ComponentState.ERROR)
            }
        } catch (e: any) {
            console.error(e)
            setComponentState(ComponentState.ERROR)
        }
    }

    const listFrameEntries = async (project: Project, recordingSession: RecordingSession) => {
        return CloudApi.listFrameEntries(project.uid, recordingSession.sessionId, {
            validateStatus: (number) => true,
        })
    }

    const getVisualizeProcessingTracker = async (project: Project, recordingSession: RecordingSession) => {
        return CloudApi.getVisualizeProcessingTracker(project.uid, recordingSession.sessionId, {
            validateStatus: (number) => true,
        })
    }

    const addTimeDistributionPanel = () => {
        const panelKey = { key: `${Date.now()}` } as PanelKey
        setDashboard({
            ...dashboard,
            timeDistributionPanels: [
                ...(dashboard?.timeDistributionPanels ?? []),
                { panelKey, selectedFrame: undefined } as TimeDistributionPanel,
            ],
        } as Dashboard)
        productAnalyticsClient.track(AnalyticsActions.ADD_TIME_DISTRIBUTION_PANEL)
    }

    const removeTimeDistributionPanel = (panelKey: PanelKey) => {
        console.log(`Removing Jitter W/ key=${panelKey}`)
        setDashboard({
            ...dashboard,
            timeDistributionPanels: (dashboard?.timeDistributionPanels ?? []).filter(
                (it) => it.panelKey.key !== panelKey.key
            ),
        } as Dashboard)
        productAnalyticsClient.track(AnalyticsActions.REMOVE_TIME_DISTRIBUTION_PANEL)
    }

    const addMapPanel = () => {
        const panelKey = { key: `${Date.now()}` } as PanelKey
        console.log(`Adding Map W/ key=${panelKey}`)
        setDashboard({
            ...dashboard,
            mapPanels: [
                ...(dashboard?.mapPanels ?? []),
                { panelKey, latitudeSignal: undefined, longitudeSignal: undefined } as MapPanel,
            ],
        } as Dashboard)
        productAnalyticsClient.track(AnalyticsActions.ADD_MAP_PANEL)
    }

    const removeMapPanel = (panelKey: PanelKey) => {
        console.log(`Removing Map W/ key=${panelKey}`)
        setDashboard({
            ...dashboard,
            mapPanels: (dashboard?.mapPanels ?? []).filter((it) => it.panelKey.key !== panelKey.key),
        } as Dashboard)
        productAnalyticsClient.track(AnalyticsActions.REMOVE_MAP_PANEL)
    }

    const addSignalTimeSeriesPanel = () => {
        const panelKey = { key: `${Date.now()}` } as PanelKey
        console.log(`Adding Signal Time Series W/ key=${panelKey}`)
        setDashboard({
            ...dashboard,
            timeSeriesPanels: [
                ...(dashboard?.timeSeriesPanels ?? []),
                { panelKey, selectedSignals: [], hiddenSignals: [] } as TimeSeriesPanel,
            ],
        } as Dashboard)
        productAnalyticsClient.track(AnalyticsActions.ADD_TIME_SERIES_PANEL)
    }

    const removeSignalTimeSeriesPanel = (panelKey: PanelKey) => {
        console.log(`Removing Signal Time Series W/ key=${panelKey}`)
        setDashboard({
            ...dashboard,
            timeSeriesPanels: (dashboard?.timeSeriesPanels ?? []).filter((it) => it.panelKey.key !== panelKey.key),
        } as Dashboard)
        productAnalyticsClient.track(AnalyticsActions.REMOVE_TIME_SERIES_PANEL)
    }

    const addVisualizationDropdown = () => {
        return (
            <>
                <Dropdown className="" show={showDropdown}>
                    <Dropdown.Menu className="py-2 text-left remotive-dropdown-light border-0 shadow">
                        <Dropdown.Item
                            as={Button}
                            className="m-0 py-1 px-3 rounded-0 text-dark text-center remotive-font-md d-flex align-items-center justify-content-start"
                            // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                            onMouseDownCapture={() => {
                                addTimeDistributionPanel()
                            }}
                        >
                            <div className="d-flex align-items-center">
                                <JitterIcon sx={{ fontSize: 20 }} />
                                <p className="ms-2 m-0 remotive-font">Frame Time Distribution</p>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Button}
                            className="m-0 py-1 px-3 rounded-0 text-dark text-center remotive-font-md d-flex align-items-center justify-content-start"
                            // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                            onMouseDownCapture={() => {
                                addMapPanel()
                            }}
                        >
                            <div className="d-flex align-items-center">
                                <MapIcon sx={{ fontSize: 17 }} />
                                <p className="ms-2 m-0 remotive-font">Map</p>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    const addVisualizationButton = () => {
        return (
            <div>
                <div className="me-0">
                    <button
                        disabled={componentState !== ComponentState.DONE}
                        style={{
                            borderRadius: '40px 0px 0px 40px',
                        }}
                        className="btn p-1 m-0 mt-1 remotive-btn-primary"
                        onClick={() => {
                            addSignalTimeSeriesPanel()
                        }}
                    >
                        <div className="d-flex p-0 justify-content-center align-items-center ps-2 pe-1">
                            <AddIcon sx={{ fontSize: 31 }} />
                            <p className="mx-2 m-0 remotive-font">Signal Time Series</p>
                        </div>
                    </button>
                    <button
                        disabled={componentState !== ComponentState.DONE}
                        style={{
                            borderRadius: '0px 40px 40px 0px',
                        }}
                        className="btn border-0 py-1 m-0 mt-1 ps-0 pe-1 remotive-btn-primary-dark border-start border-2"
                        onBlur={() => {
                            setShowDropdown(false)
                        }}
                        onClick={() => {
                            setShowDropdown(!showDropdown)
                        }}
                    >
                        {showDropdown ? <DropupIcon sx={{ fontSize: 35 }} /> : <DropdownIcon sx={{ fontSize: 35 }} />}
                    </button>
                </div>
            </div>
        )
    }

    const addNewVisualizationButton = () => {
        return (
            <>
                <div className="my-5 d-flex flex-column justify-content-center align-items-center">
                    {addVisualizationButton()}
                    {addVisualizationDropdown()}
                </div>
            </>
        )
    }

    const renderMapPanels = () => {
        if (dashboard?.mapPanels) {
            return dashboard.mapPanels
                .sort((panelA, panelB) => panelA.panelKey.key.localeCompare(panelB.panelKey.key))
                .map((it) => {
                    return (
                        <div key={it.panelKey.key} className="col-12 col-xl-6 p-0">
                            <Card className="m-1 border-0 bg-white rounded-3 shadow-sm" style={{ height: 550 }}>
                                <MapContainer
                                    updatePanel={(panel) =>
                                        setDashboard({
                                            ...dashboard,
                                            mapPanels: dashboard.mapPanels
                                                .filter((panel) => panel.panelKey.key !== it.panelKey.key)
                                                .concat(panel)
                                                .sort(),
                                        })
                                    }
                                    availableFrameEntries={availableFrameEntries ?? []}
                                    latitudeSignal={it.latitudeSignal}
                                    longitudeSignal={it.longitudeSignal}
                                    panelKey={it.panelKey}
                                    removeThisPanelFunction={() => removeMapPanel(it.panelKey)}
                                    currentProject={props.currentProject}
                                    recordingSessionId={props.currentRecording?.sessionId}
                                />
                            </Card>
                        </div>
                    )
                })
        }
        return <></>
    }

    const renderJitterDetectionPanels = () => {
        if (dashboard?.timeDistributionPanels) {
            return dashboard?.timeDistributionPanels
                .sort((panelA, panelB) => panelA.panelKey.key.localeCompare(panelB.panelKey.key))
                .map((it) => (
                    <div key={it.panelKey.key} className="col-12 col-xl-6 p-0">
                        <Card className="m-1 border-0 bg-white rounded-3 shadow-sm" style={{ height: 550 }}>
                            <TimeDistributionContainer
                                availableFramesWithCycleTime={
                                    (availableFrameEntries ?? [])
                                        .map((it) => {
                                            if (it.cnt > 0 && it.cycleTime > 0) {
                                                return it
                                            }
                                            return undefined
                                        })
                                        .filter((it) => it !== undefined) as Array<FrameEntry>
                                }
                                updatePanel={(panel) =>
                                    setDashboard({
                                        ...dashboard,
                                        timeDistributionPanels: dashboard.timeDistributionPanels
                                            .filter((panel) => panel.panelKey.key !== it.panelKey.key)
                                            .concat(panel),
                                    })
                                }
                                selectedFrame={it.selectedFrame}
                                removeThisPanelFunction={() => removeTimeDistributionPanel(it.panelKey)}
                                panelKey={it.panelKey}
                                currentProject={props.currentProject}
                                recordingSessionId={props.currentRecording?.sessionId}
                            />
                        </Card>
                    </div>
                ))
        }
        return <></>
    }

    const renderSignalTimeSeriesPanels = () => {
        if (dashboard?.timeSeriesPanels) {
            return dashboard?.timeSeriesPanels
                .sort((panelA, panelB) => panelA.panelKey.key.localeCompare(panelB.panelKey.key))
                .map((it) => (
                    <div key={it.panelKey.key} className="col-12 p-0 mb-2">
                        <Card className="m-1 h-100 border-0 bg-white rounded-3 shadow-sm" style={{ minHeight: 500 }}>
                            <TimeSeriesContainer
                                updatePanel={(panel) =>
                                    setDashboard({
                                        ...dashboard,
                                        timeSeriesPanels: dashboard.timeSeriesPanels
                                            .filter((panel) => panel.panelKey.key !== it.panelKey.key)
                                            .concat(panel),
                                    })
                                }
                                availableFrameEntries={availableFrameEntries ?? []}
                                selectedSignals={it.selectedSignals}
                                hiddenSignals={it.hiddenSignals}
                                removeThisPanelFunction={() => removeSignalTimeSeriesPanel(it.panelKey)}
                                panelKey={it.panelKey}
                                currentUser={props.currentUser}
                                currentProject={props.currentProject}
                                recordingSession={props.currentRecording}
                                availableAnnotations={availableAnnotations}
                                safeFetchAndSetAvailableAnnotations={safeFetchAndSetAvailableAnnotations}
                                sharedExtremes={sharedExtremes}
                                setSharedExtremes={setSharedExtremes}
                            />
                        </Card>
                    </div>
                ))
        }
        return <></>
    }

    const crunchRecording = () => {
        setComponentState(ComponentState.QUEUED)
        CloudApi.requestCrunchRecording(props.currentProject!, props.currentRecording!.sessionId)
            .then((e) => {
                setComponentState(ComponentState.CRUNCHING)
                console.log(e)
            })
            .catch((e) => {
                setComponentState(ComponentState.PROCESSING_REQUIRED)
                toast.error(formattedToastMessage('Failed to process recording', e.response.data), {
                    autoClose: 5_000,
                })
            })
        productAnalyticsClient.track(AnalyticsActions.PREAPRE_FOR_ANALYTICS, {
            recordingSessionId: props.currentRecording?.sessionId,
        })
    }

    const prepareForAnalyticsHero = (text: string, isButtonDisabled: boolean) => {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center my-5">
                <div style={{ height: 160, width: 60 }}>
                    <img
                        style={{ marginTop: 10 }}
                        width={'auto'}
                        height={80}
                        src={'https://releases.beamylabs.com/cloud-console-assets/WELCOME_ANALYZE.png'}
                    ></img>
                    <img
                        style={{ marginTop: -50, marginLeft: -80 }}
                        width={'auto'}
                        height={80}
                        src={'https://releases.beamylabs.com/cloud-console-assets/WELCOME_BROKER_APP.png'}
                    ></img>
                </div>
                <p className={`remotive-font-xl mb-0`}>{text}</p>
                <p className={`remotive-font-sm text-secondary mb-0`} style={{ maxWidth: 540 }}>
                    The recording data has to be processed before any signals can be visualized, this is only done once
                    per recording. The corresponding signal database files must have been previously uploaded.
                </p>
                <button
                    className="btn remotive-btn remotive-btn-success mt-4"
                    disabled={isButtonDisabled}
                    onClick={() => {
                        crunchRecording()
                    }}
                >
                    Process recording data
                </button>
            </div>
        )
    }

    const urlEncodedDashboard = (dashboard: Dashboard) => {
        const storableDashboard = convertToStorableDashboard(dashboard)
        return encodeURIComponent(JSON.stringify(storableDashboard))
    }

    const prepareForAnalytics = () => {
        return (
            <>
                <div className="mx-0 d-flex align-items-center flex-fill flex-truncate">
                    <div className="my-3 w-100">
                        <div className="text-center h-25">
                            {props.currentProject &&
                                hasPermission(
                                    Permission.PROJECT_EDITOR_RECORDING,
                                    props.currentBillableUnit,
                                    props.currentProject
                                ) && (
                                    <>
                                        {prepareForAnalyticsHero(
                                            'The recording data has not yet been processed',
                                            false
                                        )}
                                    </>
                                )}
                            {props.currentProject &&
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_RECORDING,
                                    props.currentBillableUnit,
                                    props.currentProject
                                ) && (
                                    <>
                                        {prepareForAnalyticsHero(
                                            'You need to be a project editor to process recording data',
                                            true
                                        )}
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const enableVisualizeFeature = async () => {
        try {
            const request: FeatureEnabledStateRequest = { isEnabled: true, featureKey: FeatureKey.VISUALIZE_RECORDING }
            const response = await CloudApi.putFeatureEnabledState(request, props.currentBillableUnit!.organisation.uid)
            if (response.status === 200) {
                window.location.reload()
            }
        } catch (e: any) {
            console.error(e)
            toast.error(
                formattedToastMessage(
                    'Failed to request access',
                    'The feature could not be enabled due to an unknown error.'
                )
            )
        }
    }

    const requestAccessToFeatureInformation = () => {
        return (
            <div className="mx-0 d-flex align-items-center flex-fill flex-truncate">
                <div className="my-3 w-100">
                    <div className="text-center h-25">
                        <>
                            <div className="d-flex flex-column justify-content-center align-items-center my-5">
                                <div style={{ height: 160, width: 60 }}>
                                    <img
                                        style={{ marginTop: 10 }}
                                        width={'auto'}
                                        height={80}
                                        src={'https://releases.beamylabs.com/cloud-console-assets/WELCOME_ANALYZE.png'}
                                    ></img>
                                    <img
                                        style={{ marginTop: -50, marginLeft: -80 }}
                                        width={'auto'}
                                        height={80}
                                        src={
                                            'https://releases.beamylabs.com/cloud-console-assets/WELCOME_BROKER_APP.png'
                                        }
                                    ></img>
                                </div>
                                <p className={`remotive-font-xl mb-0`}>This feature is in closed beta</p>
                                <p className={`remotive-font-sm text-secondary mb-0`} style={{ maxWidth: 540 }}>
                                    With this feature you can visualize all the signal data available in this recording
                                    session in graphs & maps to get a quick overview of the data.
                                </p>
                                <button
                                    className="btn remotive-btn remotive-btn-primary mt-4"
                                    onClick={() => {
                                        productAnalyticsClient.track(AnalyticsActions.REQUEST_ACCESS)
                                        enableVisualizeFeature()
                                    }}
                                >
                                    Request access
                                </button>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        )
    }

    const prepareForAnalyticsOrNone = () => {
        switch (componentState) {
            case ComponentState.LOADING:
                return (
                    <Card className="shadow-sm rounded-4 border-0 text-start mb-1">
                        <Card.Body className="p-1">
                            <div style={{ minHeight: 173 }}>
                                <LoadingContainer spinnerSize="sm" />
                            </div>
                        </Card.Body>
                    </Card>
                )

            case ComponentState.ERROR:
                return (
                    <Card className="shadow-sm rounded-4 border-0 text-start mb-1">
                        <Card.Body
                            className="p-1 d-flex align-items-center justify-content-center"
                            style={{ minHeight: 229 }}
                        >
                            <ErrorContainer />
                        </Card.Body>
                    </Card>
                )

            case ComponentState.NOT_ENABLED:
                return (
                    <Card className="shadow-sm rounded-4 border-0 text-start mb-1">
                        <Card.Body className="p-1">{requestAccessToFeatureInformation()}</Card.Body>
                    </Card>
                )

            case ComponentState.QUEUED:
                return (
                    <Card className="shadow-sm rounded-4 border-0 text-start mb-1">
                        <Card.Body className="p-1">
                            <div className="my-5 d-flex justify-content-center">
                                <div
                                    className="text-center py-5 my-5 d-flex align-items-center flex-column"
                                    style={{ maxWidth: 780 }}
                                >
                                    {crunchingCardContentQueued()}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                )

            case ComponentState.CRUNCHING:
                return (
                    <Card className="shadow-sm rounded-4 border-0 text-start mb-1">
                        <Card.Body className="p-1">
                            <div className="my-5 d-flex justify-content-center">
                                <div
                                    className="text-center py-5 my-5 d-flex align-items-center flex-column"
                                    style={{ maxWidth: 780 }}
                                >
                                    {crunchingCardContent()}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                )

            case ComponentState.PROCESSING_REQUIRED:
                return (
                    <Card className="shadow-sm rounded-4 border-0 text-start mb-1">
                        <Card.Body className="p-1">{prepareForAnalytics()}</Card.Body>
                    </Card>
                )

            default:
                return <></>
        }
    }

    const crunchingStepText = (latestProcessingTracker: VisualizeProcessingTracker) => {
        switch (latestProcessingTracker.step) {
            case VisualizeProcessingTrackerStep.DECODE_DBC:
                return <p className="remotive-font-sm text-secondary mb-1">Decoding signal database file(s)...</p>
            case VisualizeProcessingTrackerStep.STORE_DBC:
                return <p className="remotive-font-sm text-secondary mb-1">Storing decoded signal database(s)...</p>
            case VisualizeProcessingTrackerStep.DECODE_RECORDINGS:
                return <p className="remotive-font-sm text-secondary mb-1">Decoding recording file(s)...</p>
            case VisualizeProcessingTrackerStep.STORE_RECORDINGS:
                return <p className="remotive-font-sm text-secondary mb-1">Storing decoded recording signal data...</p>
        }
    }

    const crunchingCardContentQueued = () => {
        return (
            <>
                <InfoRounded className="remotive-primary-40-color mb-3" sx={{ fontSize: 50 }} />
                <p className="fs-5 mb-1">Recording is queued for processing</p>
                <p className="remotive-font-md text-secondary">
                    The recorded signal data will soon be processed, depending on the size of your recording this may
                    take a while.
                </p>
                <div className="mt-4" style={{ maxWidth: 340 }}>
                    <Spinner size="sm" className="mb-1" />
                </div>
            </>
        )
    }

    const crunchingErrorText = (errors: Array<ProcessingError>) => {
        if (errors.length > 0) {
            return errors[0].message
        }
        return 'An unknown error occurred when processing this recording'
    }

    const crunchingCardContent = () => {
        if (latestProcessingTracker === undefined) {
            return crunchingCardContentQueued()
        }
        switch (latestProcessingTracker.status) {
            case VisualizeProcessingTrackerStatus.QUEUED:
                return crunchingCardContentQueued()

            case VisualizeProcessingTrackerStatus.RUNNING:
                return (
                    <>
                        <InfoRounded className="remotive-primary-40-color mb-3" sx={{ fontSize: 50 }} />
                        <p className="fs-5 mb-1">Processing recorded signal data...</p>
                        <p className="remotive-font-md text-secondary">
                            The recorded signal data is being processed, depending on the size of your recording this
                            may take a while.
                        </p>
                        <div className="mt-4" style={{ maxWidth: 340 }}>
                            <Spinner size="sm" className="mb-1" />
                            {crunchingStepText(latestProcessingTracker)}
                        </div>
                    </>
                )

            case VisualizeProcessingTrackerStatus.FINISHED:
                return (
                    <>
                        <SuccessIcon className="remotive-success-60-color mb-3" sx={{ fontSize: 50 }} />
                        <p className="fs-5 mb-1">Processing done!</p>
                        <p className="remotive-font-md text-secondary">Finishing up...</p>
                        <div className="mt-4" style={{ maxWidth: 340 }}>
                            <Spinner size="sm" className="mb-1" />
                        </div>
                    </>
                )

            case VisualizeProcessingTrackerStatus.FAILED:
                return (
                    <>
                        <WarningIcon className="text-warning mb-3" sx={{ fontSize: 50 }} />
                        <p className="fs-5 mb-1">Processing failed</p>
                        <p className="remotive-font-md text-secondary">
                            {crunchingErrorText(latestProcessingTracker.errors)}
                        </p>
                        <button onClick={() => crunchRecording()} className="btn remotive-btn-md remotive-btn-primary">
                            Retry
                        </button>
                    </>
                )
        }
    }

    return (
        <>
            {componentState === ComponentState.DONE && (
                <OverviewAccordion
                    availableAnnotations={availableAnnotations ?? []}
                    availableFrameEntries={availableFrameEntries ?? []}
                    shareableDashboardLink={shareableDashboardLink}
                    currentUser={props.currentUser}
                    currentProject={props.currentProject}
                    currentBillableUnit={props.currentBillableUnit}
                    currentRecordingSession={props.currentRecording}
                    safeFetchAndSetAvailableAnnotations={safeFetchAndSetAvailableAnnotations}
                />
            )}
            {prepareForAnalyticsOrNone()}
            {componentState === ComponentState.DONE && (
                <>
                    <div className="d-flex flex-wrap mt-2">
                        {renderSignalTimeSeriesPanels()}
                        {renderMapPanels()}
                        {renderJitterDetectionPanels()}
                    </div>
                    {addNewVisualizationButton()}
                </>
            )}
        </>
    )
}
